<template>
    <div class="container">
        <section class="orders-header">
            <h1 class="titlePage titlePage_textLeft">{{getUserName}}</h1>

            <breadcrumbs v-if="!isEdit"></breadcrumbs>

        </section>

        <section>
            <h2 class="titlePage titlePage_subtitle titlePage_mb">SELECT INSERT COLOUR</h2>
            <div class="row">
                <div class="col-12 col-md-6">
                    <form action="" class="form" v-on:submit.prevent>
                        <label class="form__label">
                            <input type="text" class="form__input"
                                   placeholder="Type to Search"
                                   name="search color"
                                   v-model="color"
                            >
                        </label>
                        <button type="button" class="btn_link">
                            <svg-icon name="search"/>
                        </button>
                    </form>
                </div>
            </div>

            <section class="item-types">
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-sm-3 col-6"
                         v-for="colour in defaultColours"
                         :key="colour.id"
                         @click="selectDefaultColor(colour)"
                    >
                        <div class="box">
                            <div class="colours colour_box">
                                <img class="" :src="colour.image" alt="color.colour_name" v-if="colour.image">
                                <p v-else-if="colour.hexcode">
                                    <svg-icon name="palette" class="large_icon" :style="{color: colour.hexcode}"/>
                                </p>
                                <p v-if="!colour.image && !colour.hexcode">
                                    <svg-icon name="palette" class="large_icon"/>
                                </p>
                            </div>
                                <p v-if="colour.is_same_as_frame" class="text_default">
                                    <b class="text_red">Same as Frame</b><br/>({{ colour.colour_name }})
                                </p>
                                <p v-else class="text_default">{{ colour.colour_name }}</p>
                        </div>
                    </div>
                </div>
            </section>


            <div class="divider">
                <p class="divider__text text_green">OR SELECT BELOW</p>
            </div>
        </section>
        <section class="item-types">
            <div class="row"
                 v-for="(item, key, index) in colors"
            >
                <div class="col-12">
                    <h2 class="titlePage_subtitle titlePage">{{key}}</h2>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3 col-6"
                     v-for="color in item.colours"
                     :key="color.id"
                     @click="selectColors(color, item, key)"
                >
                    <div class="box">
                        <div class="colours colour_box">
                            <v-lazy-image-plugin :src="color.image" v-if="color.image"/>
                            <p v-else-if="color.hexcode">
                                <svg-icon name="palette" class="large_icon box__img" :style="{color: color.hexcode}"/>
                            </p>
                            <p v-if="!color.image && !color.hexcode">
                                <svg-icon name="palette" class="large_icon box__img"/>
                            </p>
                        </div>
                        <p class="text_default">{{color.colour_name}}</p>
                    </div>
                </div>
            </div>
        </section>

        <div class="modal modal_wrap" v-if="warning">
            <div class="modal_popup">
                <p class="text text_white">Chosen colour requires additional price. Please, click "I Accept" button if
                    you want to continue.</p>
                <div class="justify-content-between">
                    <button class="btn btn_transparent" @click="warning = !warning"><svg-icon name="solid/step-backward" /> Back</button>
                    <button class="btn btn_transparent" @click="accept"><svg-icon name="solid/check" /> I Accept</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from "../../router";
    import breadcrumbs from "./../parts/breadcrumbs";
    import {searchColors, simpleSearch} from '../../helpers/search';
    import VLazyImagePlugin from "v-lazy-image";

    export default {
        name: "SelectInsertColors",
        data() {
            return {
                color: '',
                warning: false,
                colorItem: {},
            }
        },
        components: {breadcrumbs, VLazyImagePlugin},
        methods: {
            selectColors(target, parent, group) {
//        localStorage.setItem('colorGroup', group);
                this.colorItem = target;
//        this.$parent.exchangeData = 'selectOptions';
                let path = this.$store.getters['app/orderBuilderPath']('ins_color_id>' + target.id);

                if (target.is_show_price_warning) {
                    this.warning = !this.warning;
                }
                else {
                    this.$store.commit('orderPosition/setInsertColour', {
                        id: target.id,
                        // is_same_as_frame: target.id == this.$store.getters['orderPosition/orderPosition'].colour.id,
                        is_same_as_frame: target.is_same_as_frame,
                        short_name: target.name,
                        full_name: target.full_name
                    });
                    this.$store.dispatch(
                        'app/addBreadcrumb',
                        {
                            route: {
                                step: 'Insert Colour'
                            },
                            name: target.colour_name,
                        }
                    );
//          router.push({path: path});
                }
            },
            selectDefaultColor(colour) {
                if (colour.is_show_price_warning) {
                    this.warning = !this.warning;
                } else {
                    this.selectColors(colour, null, null)
                }
            },

            accept() {
                this.warning = !this.warning;

                this.$store.commit('orderPosition/setInsertColour', {
                    id: this.colorItem.id,
                    same_as_frame: this.colorItem.id == this.$store.getters['orderPosition/orderPosition'].colour.id,
                    short_name: this.colorItem.name,
                    full_name: this.colorItem.full_name
                });

            },
        },
        watch: {
            color(n) {
                this.$store.commit('colors/filtered', searchColors(n, this.allColors));
            }
        },
        computed: {
            defaultColours() {
                const def = Object.values(this.colors).flatMap(i => i.colours).filter(c => {return c.is_same_as_frame})
                if (def.length == 0)
                    return []
                    const sys_def = Object.values(this.colors).flatMap(i => i.colours).filter(c => {return c.is_system_default && def.filter(cc => {return cc.id === c.id}) == 0})
                return def.concat(sys_def)
            },
            isEdit() {
                return this.$store.getters['orderPosition/isCompleted']
            },

            getUserName() {
                return localStorage.getItem('nameUser');
            },
            user() {
                return this.$store.getters['colors/user'];
            },
            allColors() {
                return this.$store.getters['colors/colors'];
            },
            colors() {
                return this.$store.getters['colors/filtrated'];
            },
        },

        mounted() {
            this.$store.dispatch('colors/getInsertColors');
        },

        created() {
//      this.$store.commit('options/cleanOptions');
        },

    }
</script>

<style scoped lang="scss">

    @import "../../../src/assets/scss/utils/vars";

    .box {
        margin-bottom: 1rem;
        &__img {
            /*position: static;*/
            /*display: block;*/
            /*margin: 0 auto;*/
            /*margin-bottom: 20px;*/
            /*width: 100%;*/
            /*max-width: 160px;*/
            /*height: 100px;*/
        }
        p {
            text-align: center;
        }
    }

    .form {
        position: relative;
        .btn_link {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
            svg {
                color: $green;
            }
        }

        &__input {
            padding-right: 40px;
            border: 2px solid #0b3553;
        }

    }

    .justify-content-between {
        display: flex;
    }

</style>
